import { graphql, useStaticQuery } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import React, { useState } from 'react'
import MetaImage from '../../assets/images/meta/genai-impact.png'
import tag from '../../assets/images/tags/coming-soon.svg'
import Layout from '../../components/Layout'
import CTABlock from '../../components/blocks/CTABlock'
import HeroBlock from '../../components/blocks/HeroBlock'
import IllustrationBlock from '../../components/blocks/IllustrationBlock'
import LeadBlock from '../../components/blocks/LeadBlock'
import MosaicBlock from '../../components/blocks/MosaicBlock'
import QuoteBlock from '../../components/blocks/QuoteBlock'
import { FeaturedBlogArticles } from '../../components/changelog/FeaturedBlogArticles'
import Box from '../../components/primitives/Box'
import { responsiveScale } from '../../styles/helpers'
import Br from '../../components/system/Br'
import EmailInputCTA from '../../components/EmailInputCTA'

export const query = graphql`
  {
    backgroundImage: file(
      relativePath: { eq: "images/backgrounds/genai.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, quality: 99)
      }
    }
    lead: file(relativePath: { eq: "images/lead/genai.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1200)
      }
    }
    mosaicLeft: file(relativePath: { eq: "images/mosaic/ai-metrics.png" }) {
      childImageSharp {
        gatsbyImageData(width: 650)
      }
    }
    mosaicRight: file(relativePath: { eq: "images/mosaic/ai-surveys.png" }) {
      childImageSharp {
        gatsbyImageData(width: 650)
      }
    }
    mosaicLeft2: file(relativePath: { eq: "images/mosaic/ai-adoption.png" }) {
      childImageSharp {
        gatsbyImageData(width: 650)
      }
    }
  }
`

const AiPage = () => {
  const data = useStaticQuery(query)
  const [isJoinWaitListSubmitted, setIsJoinWaitListSubmitted] = useState(false)

  function onCtaSubmit() {
    // Use the same state for all CTAs in the page
    setIsJoinWaitListSubmitted(true)
  }

  return (
    <Layout
      title="Measure the impact of GitHub Copilot & other GenAI coding tools"
      variant="dark"
      isNew
      description="Combine developer experience surveys, adoption data, and usage patterns to understand how GenAI coding tools play into your software organization’s productivity."
      metaImage={MetaImage}
    >
      <HeroBlock
        tag={tag}
        title={<>Measure the impact of GitHub Copilot and other AI tools</>}
        content="Combine developer experience surveys, adoption metrics, and usage patterns to understand how GenAI coding tools play into your software organization’s productivity."
        backgroundImage={getImage(data.backgroundImage)!}
        customCta={
          <PageCTA
            isSubmitted={isJoinWaitListSubmitted}
            onSubmit={onCtaSubmit}
          />
        }
      />
      <LeadBlock
        heading="A better way to understand the impact of GenAI coding tools"
        content="Swarmia allows you to measure engineers’ adoption of and satisfaction with AI coding tools — all without making dangerous comparisons between AI-assisted and non-AI-assisted work."
      />
      <Box>
        <IllustrationBlock
          paddingTop={responsiveScale(64)}
          paddingBottom={responsiveScale(32)}
          image={getImage(data.lead)!}
          noPlaceHolder
          rounded
          shadow
        />
      </Box>
      <QuoteBlock person="pau" />
      <LeadBlock
        heading="Find different ways to slice and dice GenAI data"
        content="Whether your interested in seeing how fast AI-assisted pull requests move through the system or want to hear directly from your engineers, Swarmia has the data you need."
      />
      <MosaicBlock
        title={<>Measure GitHub Copilot&nbsp;adoption</>}
        content={
          <>
            How many of your software engineers are actively using GitHub
            Copilot? Is the adoption increasing or has it stagnated?
            <br />
            <br />
            Swarmia allows you to understand and track changes in GitHub Copilot
            adoption and use.
          </>
        }
        image={getImage(data.mosaicLeft)!}
      />
      <MosaicBlock
        title="Ask engineers direct questions about their use of AI"
        content={
          <>
            Run developer experience surveys in Swarmia to find out how software
            engineers feel about the AI tools they’re using.
            <br />
            <br />
            Use our ready-made questions to measure developers’ satisfaction
            with AI tools or add your custom questions to drill into specific
            use cases.
          </>
        }
        image={getImage(data.mosaicRight)!}
        imageAlign="right"
      />
      <MosaicBlock
        title={<>Keep track of Copilot use&nbsp;cases</>}
        content={
          <>
            What percentage of Copilot suggestions are accepted? Is there a
            difference between different languages?
            <br />
            <br />
            Swarmia makes it easy to understand the most powerful use cases for
            AI in your organization so you can spread the knowledge across
            teams.
          </>
        }
        image={getImage(data.mosaicLeft2)!}
      />
      <Box paddingBottom={responsiveScale(64)}>
        <CTABlock
          title="Be the first to hear about new AI impact features in Swarmia"
          customCta={
            <PageCTA
              isSubmitted={isJoinWaitListSubmitted}
              onSubmit={onCtaSubmit}
            />
          }
        />
        <FeaturedBlogArticles
          heading="More from the swarmia blog"
          articles={[
            '/blog/continuous-integration/',
            '/blog/measuring-software-development-productivity/',
            '/blog/balancing-engineering-investments/',
          ]}
          newStyles
        />
      </Box>
    </Layout>
  )
}

const PageCTA = ({
  isSubmitted,
  onSubmit,
}: {
  isSubmitted: boolean
  onSubmit: () => void
}) => {
  const contentAfterSubmit = (
    <>
      You&apos;re on the waitlist! Keep an eye out for an email from us. <Br />{' '}
      In the meantime, get to know{' '}
      <a href="/product-tour/">what Swarmia can do today</a>.
    </>
  )

  return (
    <Box paddingTop={40} width="100%" maxWidth={640}>
      <EmailInputCTA
        hubspotFormId="cbf27204-3c0c-4779-bb9f-afde73ccb626"
        buttonContent="Join the waitlist"
        isSubmitted={isSubmitted}
        onSubmit={onSubmit}
        contentAfterSubmit={contentAfterSubmit}
        emojiAfterSubmit="🎉"
        requireWorkEmail
      />
    </Box>
  )
}

export default AiPage
